import {
  AutoComplete,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
} from 'antd';
import { ProviderSelectorContainer } from '../../shared/provider-selector/provider-selector.containers';
import dayjs from 'dayjs';
import { useReceivableNames } from '../../utils/api.hook';
import { map } from 'lodash';
import { FunctionInfoComponent } from '../../shared/function-info.component';

type ReceivableFormComponentProps = {
  form: FormInstance;
  data?: {
    dueTime: string;
    name: string;
    amount: number;
    note?: string | undefined;
    providerId: string;
  };
};

export function ReceivableFormComponent(props: ReceivableFormComponentProps) {
  const { form, data } = props;
  const { data: { names: receivableNames = [] } = {} } = useReceivableNames();

  return (
    <Form
      form={form}
      preserve={false}
      initialValues={data ? { ...data, dueTime: dayjs(data.dueTime) } : {}}
    >
      <ProviderSelectorContainer
        isDisabled={!!data}
        isRequired
        label="供應商"
      />
      <Form.Item
        label={
          <div className="flex gap-2">
            <span>應收日期</span>
            <FunctionInfoComponent title="此日期與對帳單有關。例如：應收日期設訂為 2024/12/05，則該費用會出現在該廠商的上半月對帳單。" />
          </div>
        }
        name="dueTime"
        rules={[
          {
            required: true,
            message: '請選擇應收日期',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="名稱"
        name="name"
        rules={[
          {
            required: true,
            message: '請輸入名稱',
          },
        ]}
      >
        <AutoComplete
          options={map(receivableNames, (name) => ({
            value: name,
          }))}
        />
      </Form.Item>
      <Form.Item
        label="金額"
        name="amount"
        rules={[
          {
            required: true,
            message: '請輸入金額',
          },
          {
            type: 'number',
            min: 1,
            message: '金額必須大於 0',
          },
        ]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item label="備註" name="note">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
}
